import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/InfoOutlined"; // Subtle info icon
import CloseIcon from "@mui/icons-material/Close";

const NewsBanner = ({ title, link }) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="bg-gray-800 text-gray-300 flex items-center justify-between px-4 py-2 w-full shadow-md">
      {/* Centered Content */}
      <div className="flex-1 flex items-center justify-center space-x-2">
        <InfoIcon className="text-gray-400" fontSize="small" />
        <div className="flex items-center space-x-2">
          <a
            href={link}
            className="text-gray-100 font-medium"
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
          <a
            href={link}
            className="text-gray-300 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </a>
        </div>
      </div>

      {/* Close Button */}
      <button
        className="p-1 hover:bg-gray-700 rounded transition"
        onClick={() => setIsVisible(false)}
        aria-label="Close Banner"
      >
        <CloseIcon className="text-gray-400" fontSize="small" />
      </button>
    </div>
  );
};

export default NewsBanner;
